import { Icon } from '@/components/icon/BpIcon';
import clone from '@sahnee/clone';
import { defineStore } from 'pinia';
import { $gettext } from 'vue-gettext';
import { Customer, useCustomerStore } from './customer';

interface State {
  hostingStatuses: HostingStatus[];
}

/**
 * A hosting status.
 */
export interface HostingStatus {
  _id: string;
  icon: Icon;
  name: string;
}

/**
 * The hosting status store.
 */
export const useHostingStatusStore = defineStore('hostingStatus', {
  ///-------------------------------------------------------------------
  /// STATE
  ///-------------------------------------------------------------------
  state: (): State => {
    return {
      hostingStatuses: [
        {
          _id: 'canceled',
          icon: {
            icon: 'xmark-large',
            color: 'red',
          },
          name: $gettext('Canceled'),
        },
        {
          _id: 'expired',
          icon: {
            icon: ['far', 'clock'],
            color: 'red',
          },
          name: $gettext('Expired'),
        },
        {
          _id: 'nearly_expired',
          icon: {
            icon: 'timer',
            color: 'yellow',
          },
          name: $gettext('Nearly expired'),
        },
        {
          _id: 'no',
          icon: {
            icon: 'xmark',
            color: 'red',
          },
          name: $gettext('No'),
        },
        {
          _id: 'yes',
          icon: {
            icon: 'check',
            color: 'green',
          },
          name: $gettext('Yes'),
        },
      ]
    }
  },
  ///-------------------------------------------------------------------
  /// GETTERS
  ///-------------------------------------------------------------------
  getters: {
    /**
     * Searches for the hosting status with the given ID and returns its name.
     * @param id The ID of the hosting status.
     * @returns The name of the hosting status.
     */
    getById: (state: State) => {
      return (id: string) => {
        return state.hostingStatuses.find(hostingStatus => hostingStatus._id === id);
      }
    },
    /**
     * Searches for the hosting status with the given ID and returns its name.
     * @param id The ID of the hosting status.
     * @returns The name of the hosting status.
     */
    getByCustomer() {
      return (customer: Customer) => {
        const now = new Date();
        if (!customer.hosting) {
          return {
            _id: 'no',
            icon: '',
            name: '',
          }
        }
        if (customer.hostingCancel) {
          return this.getById('canceled');
        } else {
          const runtimeTo = new Date(clone(customer.hostingruntimeto));
          const nearlyEnd = new Date(runtimeTo.setMonth(runtimeTo.getMonth() - 1));
          if (nearlyEnd.getTime() < now.getTime()) {
            return new Date(customer.hostingruntimeto).getTime() < now.getTime() ? this.getById('expired') : this.getById('nearly_expired');
          }
        }
        return this.getById('yes');
      }
    },
    /**
     * Searches for the hosting status with the given ID and returns its name.
     * @param id The ID of the hosting status.
     * @returns The name of the hosting status.
     */
    getByCustomerId() {
      return (id: string) => {
        const customerStore = useCustomerStore(true);
        const customer = customerStore.getById(id);
        return this.getByCustomer(customer);
      }
    },
  },
})