
import { $gettext } from 'vue-gettext';
import { byKey } from '@/utils/array';
import { BestinformedVersion, Component, Product, useVersionStore, VersionNumber } from '@/stores/version';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { currentLanguage, getTranslated } from '@/translation';
import { Customer, useCustomerStore } from '@/stores/customer';
import { localizeDate } from '@/utils/date';
import { removeHTML } from '@/utils/string';
import { Tooltip } from '@/utils/tooltip';
import { useAppStore } from '@/stores/app';
import { useEditionStore } from '@/stores/edition';
import { useHostingStatusStore } from '@/stores/hostingStatus';

export default defineComponent({
  name: 'bp-customer-license-information',
  props: {
    modelValue: {
      type: Object as PropType<Customer>,
      required: true,
    },
    hideUnlicensedProducts: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// STORES
    ///-------------------------------------------------------------------

    const appStore = useAppStore();
    const editionStore = useEditionStore(true);
    const customerStore = useCustomerStore();
    const versionStore = useVersionStore();
    const hostingStatusStore = useHostingStatusStore();

    ///-------------------------------------------------------------------
    /// EDITION
    ///-------------------------------------------------------------------

    const edition = ref('');
    watch(() => [editionStore.isLoading(), props.modelValue], () => {
      if (!editionStore.loaded || editionStore.isLoading()) { return }
      edition.value = customerStore.getBestinformedEditionName(props.modelValue);
    }, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// APPS
    ///-------------------------------------------------------------------

    const editionApps = ref<string[]>([]);
    const editionExcludedApps = ref<string[]>([]);
    
    const customerApps = ref<string[]>([]);
    const customerExcludedApps = ref<string[]>([]);

    watch(() => [editionStore.isLoading(), customerStore.isLoading(), props.modelValue], () => {
      if (!editionStore.loaded || editionStore.isLoading() || customerStore.isLoading()) return;

      const edition = editionStore.getById(customerStore.getBestinformedEdition(props.modelValue));
      editionApps.value = (edition?.apps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));
      editionExcludedApps.value = (edition?.excludedApps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));

      customerApps.value = customerStore.getBestinformedApps(props.modelValue).filter(app => !['starter', 'standard', 'alarm'].includes(app));
      customerExcludedApps.value = customerStore.getBestinformedExcludedApps(props.modelValue).filter(app => !['starter', 'standard', 'alarm'].includes(app));
    }, { immediate: true })

    const isAppActive = (appId: string) => editionApps.value.includes(appId) || customerApps.value.includes(appId);
    const isAppIncluded = (appId: string) => editionApps.value.includes(appId);
    const isAppExcluded = (appId: string) => editionExcludedApps.value.includes(appId) || customerExcludedApps.value.includes(appId);

    const appTooltip = computed<Tooltip>(() => {
      const tooltips: string[] = [];
      if (customerApps.value.length > 0 && customerApps.value.map(app => editionApps.value.includes(app)).includes(false)) {
        tooltips.push(`<p><span class="bp-customer-license-information__app--active">${$gettext('Bold')}</span>&ensp;–&ensp;${$gettext('The app is licensed and it is not included in the %{edition}', { edition: edition.value })}</p>`);
      }
      if (editionApps.value.length > 0) {
        tooltips.push(`<p><span class="bp-customer-license-information__app--included">${$gettext('Italic')}</span>&ensp;–&ensp;${$gettext('The app is included in the %{edition}', { edition: edition.value })}</p>`)
      }
      if (editionExcludedApps.value.length > 0 || customerExcludedApps.value.length > 0) {
        tooltips.push(`<p><span class="bp-customer-license-information__app--excluded">${$gettext('Strikethrough')}</span>&ensp;–&ensp;${$gettext('The app is excluded from the %{edition}', { edition: edition.value })}</p>`)
      }
      return tooltips.join('');
    });

    ///-------------------------------------------------------------------
    /// VERSION
    ///-------------------------------------------------------------------

    const versionName = ref<string>('');
    watch(() => [props.modelValue, currentLanguage.value], async () => {
      const product = await customerStore.getBestinformedVersion(props.modelValue);
      versionName.value = versionStore.getName(product);
    }, { immediate: true, deep: true });

    // PRODCUT VERSIONS
    const productVersions = ref<Product[]>([]);
    const productVersion = ref(getProductVersion(props.modelValue.products.bestinformed.version));
    watch(() => [versionStore.isLoading(), props.modelValue], () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      const productId = versionStore.findVersionByName('bestinformed')?._id ?? 'root';
      productVersions.value = versionStore.getFlatSubtree(productId)
        .filter(product => product.doc_type === 'version_product' && product._id !== productId)
        .map(product => { delete product._children; return product as Product })
      productVersion.value = getProductVersion(props.modelValue.products.bestinformed.version);
    }, { immediate: true })

    function getProductVersion(v: BestinformedVersion) {
      return versionStore.findVersionByName(v, productVersions.value)?._id || 'root'
    }

    // COMPONENTS
    const components = ref<Component[]>([]);
    watch(() => [versionStore.isLoading(), props.modelValue.editionProduct], () => {
      if (!versionStore.loaded || versionStore.isLoading() || !props.modelValue.products) { return }
      components.value = versionStore.getFlatSubtree(productVersion.value)
        .filter(component => {
          return Object.entries(props.modelValue.products).find(([, product]) => {
            return Object.keys(product.installedVersion).includes(component._id)
          }) && component.doc_type === 'version_component';
        })
        .map(component => { delete component._children; return component as Component });
    }, { immediate: true })

    // VERSION NUMBERS
    const versionNumbers = ref<Map<string, VersionNumber[]>>(new Map());
    watch(() => components.value, () => {
      versionNumbers.value = new Map(components.value.map(component => [
        component._id,
        versionStore.getFlatSubtree(component._id)
          .filter(versionNumber => versionNumber.doc_type === 'version_number')
          .map(versionNumber => { delete versionNumber._children; return versionNumber as VersionNumber })
      ]));
    }, { immediate: true })

    function getVersionNumbers(component: Component) {
      return props.modelValue.products.bestinformed.installedVersion[component.uuid] ?? [];
    }

    function getVersionNumbersMeta(component: Component) {
      const versionNumberIds = getVersionNumbers(component);
      const versionNumber = versionStore.getById(versionNumberIds) as VersionNumber | VersionNumber[];
      const versionNumbers = Array.isArray(versionNumber) ? versionNumber : [versionNumber];
      return new Map<string, VersionNumber['meta']>(versionNumbers
        .filter(versionNumber => Object.entries(versionNumber.meta).filter(
          ([key, val]) => !!versionStore.getTranslatedMetaKey(key) && !!val
        ).length > 0)
        .map(versionNumber => [transformVersionNumber(versionNumber), versionNumber.meta])
      );
    }

    function formatTransformedVersionNumber(versionNumber: string) {
      return removeHTML(versionNumber).replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&amp;', '&')
    }

    const transformVersionNumber = (versionNumber: VersionNumber) => {
      const path = versionStore.getPathById(versionNumber._id);
      const component = path
        .filter(component => component.doc_type === 'version_component')
        .map(component => versionStore.getName(component))
        .join(' ');

      if (component) {
        return `${component} ${versionNumber.version}`;
      }
      return versionNumber.version;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      appStore,
      appTooltip,
      byKey,
      components,
      customerStore,
      edition,
      formatTransformedVersionNumber,
      getTranslated,
      getVersionNumbers,
      getVersionNumbersMeta,
      hostingStatusStore,
      isAppActive,
      isAppExcluded,
      isAppIncluded,
      localizeDate,
      versionName,
      versionNumbers,
      versionStore,
    }
  }
})
