import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "flex-column gap-sm"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "bp-customer-license-information__list" }
const _hoisted_5 = { class: "bp-customer-license-information__list" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 1,
  class: "flex-column gap-sm"
}
const _hoisted_9 = { class: "flex gap-sm" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 2,
  class: "flex-column gap-sm"
}
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionStore.getRootProducts().sort(_ctx.byKey('uuid')), (product) => {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
      (!_ctx.hideUnlicensedProducts || _ctx.customerStore.hasProduct(_ctx.modelValue, product.uuid))
        ? (_openBlock(), _createBlock(_component_bp_fieldset, {
            key: product.uuid,
            class: "bp-customer-license-information__fieldset",
            "intense-border": ""
          }, _createSlots({
            legend: _withCtx(() => [
              _createVNode(_component_bp_icon, {
                color: "var(--theme-intense-border)",
                icon: _ctx.versionStore.getIcon(product)
              }, null, 8, ["icon"]),
              _createElementVNode("h4", {
                innerHTML: _ctx.versionStore.getName(product)
              }, null, 8, _hoisted_1)
            ]),
            _: 2
          }, [
            (_ctx.customerStore.hasProduct(_ctx.modelValue, product.uuid))
              ? {
                  name: "default",
                  fn: _withCtx(() => [
                    (product.uuid === 'bestinformed')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_bp_label, {
                            seamless: "",
                            label: _ctx.$gettext('Version')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_bp_loading, {
                                "model-value": !_ctx.versionName,
                                inline: ""
                              }, null, 8, ["model-value"]),
                              (!!_ctx.versionName)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    innerHTML: _ctx.versionName
                                  }, null, 8, _hoisted_3))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_bp_label, {
                            seamless: "",
                            label: _ctx.$gettext('License type')
                          }, {
                            default: _withCtx(() => [
                              (_ctx.modelValue.subscription)
                                ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                                      _createTextVNode("Subscription")
                                    ])),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                                      _createTextVNode("Purchase")
                                    ])),
                                    _: 1
                                  }))
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_bp_label, {
                            seamless: "",
                            label: _ctx.$gettext('Edition')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.edition), 1)
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          (_ctx.modelValue.products.bestinformed.version === '6')
                            ? (_openBlock(), _createBlock(_component_bp_label, {
                                key: 0,
                                seamless: ""
                              }, {
                                label: _withCtx(() => [
                                  _createVNode(_component_translate, null, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createTextVNode("App Plus")
                                    ])),
                                    _: 1
                                  }),
                                  _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                                    class: "ml-sm",
                                    icon: ['fad', 'info-circle'],
                                    key: _ctx.modelValue._id + '_' + _ctx.appTooltip
                                  })), [
                                    [_directive_tooltip, _ctx.appTooltip]
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("ul", _hoisted_4, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appStore.apps.appPlus, (app) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        class: "flex gap-sm",
                                        key: app._id
                                      }, [
                                        _createVNode(_component_bp_icon, {
                                          icon: _ctx.isAppActive(app._id) ? 'check' : 'xmark',
                                          color: _ctx.isAppActive(app._id) ? 'green' : 'red'
                                        }, null, 8, ["icon", "color"]),
                                        _createElementVNode("span", {
                                          class: _normalizeClass(['bp-customer-license-information__app', {
                  'bp-customer-license-information__app--active': _ctx.isAppActive(app._id),
                  'bp-customer-license-information__app--included': _ctx.isAppIncluded(app._id),
                  'bp-customer-license-information__app--excluded': _ctx.isAppExcluded(app._id),
                }])
                                        }, _toDisplayString(app.name), 3)
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.modelValue.products.bestinformed.version === '6')
                            ? (_openBlock(), _createBlock(_component_bp_label, {
                                key: 1,
                                seamless: ""
                              }, {
                                label: _withCtx(() => [
                                  _createVNode(_component_translate, null, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode("Additional Apps")
                                    ])),
                                    _: 1
                                  }),
                                  _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                                    class: "ml-sm",
                                    icon: ['fad', 'info-circle'],
                                    key: _ctx.modelValue._id + '_' + _ctx.appTooltip
                                  })), [
                                    [_directive_tooltip, _ctx.appTooltip]
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("ul", _hoisted_5, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appStore.apps.additionalApps, (app) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        class: "flex gap-sm",
                                        key: app._id
                                      }, [
                                        _createVNode(_component_bp_icon, {
                                          icon: _ctx.isAppActive(app._id) ? 'check' : 'xmark',
                                          color: _ctx.isAppActive(app._id) ? 'green' : 'red'
                                        }, null, 8, ["icon", "color"]),
                                        _createElementVNode("span", {
                                          class: _normalizeClass(['bp-customer-license-information__app', {
                  'bp-customer-license-information__app--active': _ctx.isAppActive(app._id),
                  'bp-customer-license-information__app--included': _ctx.isAppIncluded(app._id),
                  'bp-customer-license-information__app--excluded': _ctx.isAppExcluded(app._id),
                }])
                                        }, _toDisplayString(app.name), 3)
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_bp_label, {
                            seamless: "",
                            label: _ctx.$gettext('CALs')
                          }, {
                            default: _withCtx(() => [
                              (_ctx.modelValue.unlimited_cals)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                    _createVNode(_component_bp_icon, { icon: "infinity" })
                                  ]))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    style: _normalizeStyle(!_ctx.modelValue.numberOfLicences ? { color: 'var(--theme-text-disabled)' } : {})
                                  }, _toDisplayString(_ctx.modelValue.numberOfLicences || '–'), 5))
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_bp_label, {
                            seamless: "",
                            label: _ctx.$gettext('Mobile CALs')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                style: _normalizeStyle(!_ctx.modelValue.mobilecals ? { color: 'var(--theme-text-disabled)' } : {})
                              }, _toDisplayString(_ctx.modelValue.mobilecals || '–'), 5)
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_bp_label, {
                            seamless: "",
                            label: _ctx.$gettext('Cluster nodes')
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                style: _normalizeStyle(!_ctx.modelValue.clusternodes ? { color: 'var(--theme-text-disabled)' } : {})
                              }, _toDisplayString(_ctx.modelValue.clusternodes || '–'), 5)
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['bestinformed', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                            ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
                            : _createCommentVNode("", true),
                          ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['bestinformed', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                            ? (_openBlock(), _createBlock(_component_translate, {
                                key: 3,
                                tag: "h5"
                              }, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("Services")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['bestinformed', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                            ? (_openBlock(), _createBlock(_component_bp_label, {
                                key: 4,
                                seamless: "",
                                label: _ctx.$gettext('Hosting')
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.hostingStatusStore.getByCustomer(_ctx.modelValue)], (hostingStatus) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "flex gap-sm",
                                      key: hostingStatus?._id
                                    }, [
                                      _createVNode(_component_bp_icon, {
                                        class: "flex-0",
                                        icon: hostingStatus?.icon || ''
                                      }, null, 8, ["icon"]),
                                      _createElementVNode("span", null, _toDisplayString(hostingStatus?.name), 1)
                                    ]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["label"]))
                            : _createCommentVNode("", true),
                          ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['bestinformed', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                            ? (_openBlock(), _createBlock(_component_bp_label, {
                                key: 5,
                                seamless: "",
                                label: _ctx.$gettext('Hosting runtime')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_translate, {
                                    "translate-params": { date: _ctx.localizeDate(_ctx.modelValue.hostingruntimeto) }
                                  }, {
                                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                                      _createTextVNode("Until %{date}")
                                    ])),
                                    _: 1
                                  }, 8, ["translate-params"])
                                ]),
                                _: 1
                              }, 8, ["label"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (product.uuid === 'bestproxy')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_bp_label, {
                              seamless: "",
                              label: _ctx.$gettext('CPU Cores')
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  style: _normalizeStyle(!_ctx.modelValue.bestproxycores ? { color: 'var(--theme-text-disabled)' } : {})
                                }, _toDisplayString(_ctx.modelValue.bestproxycores || '–'), 5)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_bp_label, {
                              seamless: "",
                              label: _ctx.$gettext('RAM in GB')
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  style: _normalizeStyle(!_ctx.modelValue.bestproxyram ? { color: 'var(--theme-text-disabled)' } : {})
                                }, _toDisplayString(_ctx.modelValue.bestproxyram || '–'), 5)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_bp_label, {
                              seamless: "",
                              label: _ctx.$gettext('Runtime')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_translate, {
                                  "translate-params": { date: _ctx.localizeDate(_ctx.modelValue.bestproxyruntimeto) }
                                }, {
                                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                                    _createTextVNode("Until %{date}")
                                  ])),
                                  _: 1
                                }, 8, ["translate-params"])
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_bp_label, {
                              seamless: "",
                              label: _ctx.$gettext('Access to the web interface')
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_9, [
                                  _createVNode(_component_bp_icon, {
                                    icon: _ctx.modelValue.webaccess ? 'check' : 'xmark',
                                    color: _ctx.modelValue.webaccess ? 'green' : 'red'
                                  }, null, 8, ["icon", "color"]),
                                  (_ctx.modelValue.webaccess)
                                    ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                                          _createTextVNode("Yes")
                                        ])),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                                          _createTextVNode("No")
                                        ])),
                                        _: 1
                                      }))
                                ])
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_bp_label, {
                              seamless: "",
                              label: _ctx.$gettext('Amount of concurrent web accesses')
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  style: _normalizeStyle(!_ctx.modelValue.bestproxywebaccess ? { color: 'var(--theme-text-disabled)' } : {})
                                }, _toDisplayString(_ctx.modelValue.bestproxywebaccess || '–'), 5)
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['poolproxy', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                              ? (_openBlock(), _createElementBlock("hr", _hoisted_10))
                              : _createCommentVNode("", true),
                            ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['poolproxy', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                              ? (_openBlock(), _createBlock(_component_translate, {
                                  key: 1,
                                  tag: "h5"
                                }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Services")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['poolproxy', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                              ? (_openBlock(), _createBlock(_component_bp_label, {
                                  key: 2,
                                  seamless: "",
                                  label: _ctx.$gettext('Hosting')
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.hostingStatusStore.getByCustomer(_ctx.modelValue)], (hostingStatus) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "flex gap-sm",
                                        key: hostingStatus?._id
                                      }, [
                                        _createVNode(_component_bp_icon, {
                                          class: "flex-0",
                                          icon: hostingStatus?.icon || ''
                                        }, null, 8, ["icon"]),
                                        _createElementVNode("span", null, _toDisplayString(hostingStatus?.name), 1)
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["label"]))
                              : _createCommentVNode("", true),
                            ((_ctx.modelValue.hosting && !_ctx.modelValue.hostingCancel && ['poolproxy', 'bestproxy_system'].includes(_ctx.modelValue.hosting_type)))
                              ? (_openBlock(), _createBlock(_component_bp_label, {
                                  key: 3,
                                  seamless: "",
                                  label: _ctx.$gettext('Hosting runtime')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_translate, {
                                      "translate-params": { date: _ctx.localizeDate(_ctx.modelValue.hostingruntimeto) }
                                    }, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode("Until %{date}")
                                      ])),
                                      _: 1
                                    }, 8, ["translate-params"])
                                  ]),
                                  _: 1
                                }, 8, ["label"]))
                              : _createCommentVNode("", true)
                          ]))
                        : (product.uuid === 'bestzero')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createVNode(_component_bp_label, {
                                seamless: "",
                                label: _ctx.$gettext('Support')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_translate, {
                                    "translate-params": { date: _ctx.localizeDate(_ctx.modelValue.supportTo) }
                                  }, {
                                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                                      _createTextVNode("Until %{date}")
                                    ])),
                                    _: 1
                                  }, 8, ["translate-params"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_bp_label, {
                                seamless: "",
                                label: _ctx.$gettext('CALs')
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.modelValue.unlimited_cals)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                        _createVNode(_component_bp_icon, { icon: "infinity" })
                                      ]))
                                    : (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        style: _normalizeStyle(!_ctx.modelValue.numberOfLicences ? { color: 'var(--theme-text-disabled)' } : {})
                                      }, _toDisplayString(_ctx.modelValue.numberOfLicences || '–'), 5))
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]))
                          : _createCommentVNode("", true)
                  ]),
                  key: "0"
                }
              : {
                  name: "default",
                  fn: _withCtx(() => [
                    _createVNode(_component_translate, { tag: "em" }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("The customer doesn't have a license for this product.")
                      ])),
                      _: 1
                    })
                  ]),
                  key: "1"
                }
          ]), 1024))
        : _createCommentVNode("", true)
    ], 64))
  }), 256))
}