import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import { useEditionStore } from './edition';

interface State {
  apps: {
    appPlus: App[],
    additionalApps: App[],
  };
  forceReload: boolean;
  loading: number;
  loaded: boolean;
}

/**
 * A app.
 */
export interface App {
  _id: string;
  name: string;
  dependencies: App['_id'][]
}

export enum AppType {
  APP_PLUS = 'appPlus',
  ADDITIONAL_APPS = 'additionalApps'
}

/**
 * The app store.
 */
export const useAppStore = () => {
  // External stores
  const editionStore = useEditionStore();

  // Define a local store reference
  const useStore = defineStore('app', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        apps: {
          appPlus: [],
          additionalApps: []
        },
        forceReload: true,
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Gets all apps independent of their app type.
       */
      getAll: (state: State) => {
        return () => [
          ...state.apps.appPlus,
          ...state.apps.additionalApps
        ];
      },
      /**
       * Searches for the app with the given ID and returns its name.
       * @param id The ID of the app.
       * @returns The name of the app.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }

          const apps = [
            ...state.apps.appPlus,
            ...state.apps.additionalApps
          ];
          const predicate = (app: App) => ids.includes(app._id);

          return ids.length === 1
            ? apps.find(predicate)
            : apps.filter(predicate);
        }) as ((ids: string | [string]) => App) & ((ids: string[]) => App[])
      },
      getAppsOfEdition: (state: State) => {
        return (editionId: string | undefined, type?: AppType) => {
          if (!editionId) {
            return [];
          }

          // Get edition
          const edition = editionStore.getById(editionId);
          if (!edition) {
            return [];
          }

          // Filter apps
          let apps: App[] = [];
          if (type === undefined) {
            apps = [
              ...state.apps.appPlus,
              ...state.apps.additionalApps
            ];
          } else if (type === 'appPlus') {
            apps = state.apps.appPlus;
          } else if (type === 'additionalApps') {
            apps = state.apps.additionalApps;
          }
          return apps.filter(app => edition.apps?.includes(app._id));
        }
      },
      getAvailableAppsOfEdition: (state: State) => {
        return (editionId: string | undefined, type?: AppType) => {
          if (!editionId) {
            return [];
          }

          // Get edition
          const edition = editionStore.getById(editionId);
          if (!edition) {
            return [];
          }

          // Filter apps
          let apps: App[] = [];
          if (type === undefined) {
            apps = [
              ...state.apps.appPlus,
              ...state.apps.additionalApps
            ];
          } else if (type === AppType.APP_PLUS) {
            apps = state.apps.appPlus;
          } else if (type === AppType.ADDITIONAL_APPS) {
            apps = state.apps.additionalApps;
          }
          return apps.filter(app => !edition.excludedApps?.includes(app._id));
        }
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const response = await json<{ appPlus: App[], additionalApps: App[] }>('/api/store/apps', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.apps = {
            appPlus: response.appPlus,
            additionalApps: response.additionalApps,
          };
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  store.load();

  // Return the store reference
  return store;
};